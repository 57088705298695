.sidebar {
  .is-active {
    background-color: #359ae1;
    font-weight: 500;
    color: #fff !important;
  }

  .sub-link-adminstrator-active {
    font-weight: 700 !important;
  }
  .sub-menu-collapse {
    .sub-menu {
      background-color: #ecf7fe;
      transition: height 0.3s ease 0s;
      padding-left: 20px !important;
      z-index: 10;
      a.is__active {
        font-weight: 500;
      }
    }
  }

  .nav-item {
    .nav-icon {
      width: 100%;
    }
    .nav-link {
      cursor: pointer;
      transition: all 0.2s ease 0s;
    }

    &:hover {
      background-color: #eaecf4;
    }
  }
}

.aside {
  min-height: calc(100vh - 94px);
  min-width: 200px;
  max-width: 300px;
  width: 15%;
  box-shadow: 2px 0px 6px 0px #00000026;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  transition: width 0.3s ease-out;
  position: fixed;
  z-index: 20;
  overflow-y: auto;
  overflow-x: hidden;
  font-family: "Roboto";
  .nav-link {
    display: flex!important;
    .aside__submenu-list__title-icon{
      align-self: center;
      flex: auto;
      display: flex;
      justify-content: center;
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 10%;
      margin: auto;
    }
    .aside__submenu-list__title-chevron{
      font-size: xx-large;
      align-self: center;
      flex: auto;
    }
    &:hover {
      color: #3f6ad8 !important;
      background: #e0f3ff;
      font-weight: 500;
    }
    @media (max-width: 800px) {
      .chevron {
        margin-top: -20px !important;
      }
    }
  }

  .fa-chevron-right {
    display: block;
  }

  .fa-chevron-down {
    display: none;
  }

  .is-open {
    .fa-chevron-right {
      display: none;
    }

    .fa-chevron-down {
      display: block;
    }
    .aside__menu-sub {
      display: block;
    }
  }

  &.toggled {
    transition: width 0.3s ease-out;
    width: 6.5rem;
    min-width: 6.5rem;
  }
  .aside__menu {
    width: 100%;
    overflow-y: auto;
    height: 700px;
  }
  .aside__menu-sub {
    display: none;
    background-color: #ecf7fe;
    transition: width 0.2s;
    li {
      list-style: none;
      margin: 0;
      cursor: pointer;
      &:first-child {
        padding-top: 5px;
      }
      a {
        display: block;
        font-weight: 400;
        padding: 8.5px 15px;
        font-size: 14px;
        color: #5f5f5f;
        transition: 0.2s;
        &:hover {
          text-decoration: none;
          color: #3f6ad8;
          background: #e0f3ff;
          font-weight: 500;
        }
      }
    }
    .sub-link-adminstrator-active {
      font-weight: 700 !important;
    }
  }
  .copyright {
    padding: 0 15px 20px;
    p {
      font-size: 13px;
      color: #383838;
      font-weight: 300;
      margin-top: 12px;
    }
  }
}

::-webkit-scrollbar {
  width: 2px;
  height: 10em;
}

.fullscreen-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.overlay__wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  margin: 0;
  padding: 0;
  background-color: rgba(0,0,0, 0.9);
  z-index: 100;
  right: 0;
  bottom: 0;
  padding: 10px;
  overflow-y: scroll;
  scrollbar-width: none; /* Hide scrollbar for Firefox */

  color: rgba(255, 255, 255, 0.6);
  .close-btn {
    a {
      text-decoration: none;
      color: inherit;
    }
  }

  .overlay__avatar-section {
    .overlay__avatar {
      width: 100%;
      a {
        img {
          width: 100%;
        }
      }
    }

    .overlay__avatar-buttons {
      .overlay__avatar-button {
        border-right: solid 1px;
        align-items: center;
        svg {
          color: inherit !important;  
        }
        a {
          font-size: 0.85rem;
          color: inherit;
        }
      }
    }
  }

  a {
    padding: 8px;
    text-decoration: none;
    font-size: 36px;
    color: #818181;
    display: block;
    transition: 0.3s;
  }

  .overlay__menu-list {
    padding: 0;
    list-style: none;

    .fa-chevron-right {
      display: block;
    }
  
    .fa-chevron-down {
      display: none;
    }

    .overlay__menu__item{
      .overlay__menu-link__wrapper{
        .overlay__menu-link__icon {
          align-self: center;
          display: flex;
          flex: auto;
          justify-content: center;
          flex-grow: 0;
          flex-shrink: 0;
          flex-basis: 10%;
        }
        a {
          color: inherit;
          font-size: 0.85rem;
        }
      }
      .overlay__submenu-list__wrapper {
        display: flex; 
        flex-direction:column;

        .overlay__submenu-list__title-wrapper {
          display: flex;
          .overlay__submenu-list__title-icon{
            align-self: center;
            flex: auto;
            display: flex;
            justify-content: center;
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: 10%;
          }
          
          a {
            // font-size: x-large;
            font-size: 0.85rem;
          }

          .overlay__submenu-list__title-chevron{
            padding-top: 25px;
            font-size: 0.85rem;
            align-self: center;
            flex: auto;
          }
          &:hover {
            color: rgba(255, 255, 255, 1);
            font-weight: 500;
          }
          @media (max-width: 800px) {
            .chevron {
              margin-top: -20px !important;
            }
          }
        }

        .overlay__submenu-list__content-wrapper {
          display: flex;
          .overlay__submenu-list__content {
            display: none;
            transition: width 0.2s;
            padding-left: 20%;
            li {
              // display: flex;
              list-style: none;
              margin: 0;
              &:first-child {
                padding-top: 5px;
              }
              a {
                display: block;
                font-weight: 400;
                padding: 8.5px 15px;
                color: #5f5f5f;
                transition: 0.2s;
                // font-size: x-large;
                font-size: 0.85rem;
                color: inherit;
                &:hover {
                  text-decoration: none;
                  color: #3f6ad8;
                  background: #e0f3ff;
                  font-weight: 500;
                }
              }
            }
            .sub-link-adminstrator-active {
              font-weight: 700 !important;
            }
          }
        }
      }
      
    }

    .is-open {
      .fa-chevron-right {
        display: none;
      }
  
      .fa-chevron-down {
        display: block;
      }

      .overlay__submenu-list__wrapper {
        .overlay__submenu-list__content-wrapper {
          .overlay__submenu-list__content {
            display: block;
          }
        }
      }
    }
  }

  .copyright {
    padding: 0 15px 20px;
    p {
      font-size: 13px;
      color: #383838;
      font-weight: 300;
      margin-top: 12px;
    }
  }

  .footer-menu-mobile {
    bottom: 0;
    .copyright {
      text-align: center;
      p {
        font-size: 0.85rem;
        font-weight: 700;
        color: inherit;
      }
    }
  }
}

.overlay__wrapper::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Edge, and Opera */
}