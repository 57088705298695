#bg_login {
    width: 100%;
    background: #174c75;
    position: relative;
    top: 25%;
    overflow: hidden;
    background-size: 100%;
    font-size: 13px!important;
}
#logo {
    /* background: url(/_layouts/images/NCS.UI.Admin/bogiaothong.png) no-repeat; */
    background: url(http://quyhoach.xaydung.gov.vn/_layouts/images/NCS.UI.Admin/qh.png) no-repeat;
    margin: 10px 5%;
    padding-left: 90px;
    color: #fff;
    min-height: 81px;
    text-transform: uppercase;
    font-size: 13px;
    line-height: 20px;
}

#logo b {
    margin-bottom: 10px !important;
    clear: left;
    display: block;
    width: 100%;
    font-size: 20px !important;
    margin-top: 15px;
}
.ten_ht {
    margin-top: 10px;
    display: inline-block;
    width: 100%;
    padding: 0px 5%;
    margin-bottom: 10px;
    border-bottom: 1px solid #bfbfbf;
}
#bg_login b {
    color: #fff;
    text-transform: uppercase;
    font-size: 14px;
    margin-bottom: -10px;
    float: left;
    background: #174c75;
    padding: 0px 2px;
}
#bg_login b {
    color: #fff;
    text-transform: uppercase;
    font-size: 14px;
    margin-bottom: -8px;
    float: left;
    background: #174c75;
    padding: 0px 2px;
}
#bg_login label {
    width: 35%;
    float: left;
    font-weight: bold;
    margin-right: 0;
    margin-top: 5px;
}
.check-login-input {
    border: 1px solid gray;
    border-radius: 4px 4px 4px 4px;
    font-family: Arial,Helvetica,sans-serif;
    font-size: 12px;
    margin: 3px 0;
    padding: 4px;
    width: 55%;
}
.check-login-button {
    width: 120px;
    float: left;
    margin: 5px 5px 5px 115px;
    height: 28px;
}