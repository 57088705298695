.container-bottom {
  width: 75%;
  height: 144px;
  position: absolute;
  bottom: 0;
  left: 25%;
  background-color: white;
  padding: 10px 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  overflow-y: scroll;
  &-hide {
    height: 50px;
    width: 75%;
    background-color: unset;
    box-shadow: unset;
    position: absolute;
    bottom: 0;
    left: 25%;
    display: flex;
    justify-content: end;
    .hide-button {
        background: none;
        border: none;
        margin-right: 30px;
        background-color: white;
        height: 30px;
        width: 40px;
        border-radius: 8px;
      }
  }
  .card {
    padding: 20px;
  }

  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__title {
      font-weight: 600;
      padding: 0;
      margin: 0;
    }
    &__button {
      &-hide {
        background: none;
        border: none;
      }
    }
  }
}
.margin-10{
  margin-top: 10px;
}
.mgl-20 {
  margin-left: 12px;
}
.info-table {
  width: 100% !important;
  border: solid 1px #CCCCCC;
}
.info-table table {
  width: 100% !important;
}

.info-table table thead {
  background-color: #EEEEEE;
}

.info-table table thead th {
  text-align: center;
  padding: 2px 8px 2px 8px;
  font-weight: normal;
  border-bottom: solid 1px #000000;
}
.info-table table tbody tr {

}

.info-table table tbody tr td {
  border-bottom: solid 1px #CCCCCC;
  padding: 2px 6px 2px 6px;
}

.info-table table tbody tr td img {
  max-width: 100% !important;
}
.row-label {border-right: solid 1px #CCCCCC;}

